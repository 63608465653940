import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  icon = '../../../assets/burguer-menu.svg';
  constructor() { }

  ngOnInit() {
  }

  toogleIcon() {
    if (this.icon === '../../../assets/burguer-menu.svg') {
      this.icon = '../../../assets/iconmonstr-x-mark-9.svg';
  } else {
      this.icon = '../../../assets/burguer-menu.svg';
  }
  }


}
