import { Component, OnInit } from "@angular/core";
import {
  Decoracion,
  DecoracionService
} from "../../services/decoracion.service";

@Component({
  selector: "app-decoracion",
  templateUrl: "./decoracion.component.html",
  styleUrls: ["./decoracion.component.scss"]
})
export class DecoracionComponent implements OnInit {
  decoracion: Decoracion[] = [];
  slideDecoracion: string[];
  eventTitle: string;

  constructor(private _decoracion: DecoracionService) {}

  ngOnInit() {
    this.decoracion = this._decoracion.getDecoracion();
  }

  showSlider(slide: string[]) {
    this.slideDecoracion = slide;
  }

  name(name:string){
    this.eventTitle = name;
  }
}
