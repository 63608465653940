import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable()
export class MailingService {
  constructor(private http: HttpClient) {}
  private url = environment.AZURE_FUNCTIONS;

  public error: boolean = false;

  sendMail(contacto) {
    const dataMail = contacto.value;

    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    this.http.post(this.url, dataMail, { headers }).subscribe(
      data => {
        console.log(data);
        this.error = true;
      },
      errorService => {
        console.log("error : ", errorService);
        this.error = false;
      }
    );
  }
}
