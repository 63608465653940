import { Component, OnInit } from '@angular/core';
import { BodasService, Bodas } from '../../services/bodas.service';

@Component({
  selector: 'app-bodas',
  templateUrl: './bodas.component.html',
  styleUrls: ['./bodas.component.scss']
})
export class BodasComponent implements OnInit {
  bodas: Bodas[] = [];
  sliderToShow: string[];
  eventTitle: string;

  constructor(private _bodasService: BodasService) { }

  ngOnInit() {
    this.bodas = this._bodasService.getBodas();
  }

  showSlider(slides: string[]) {
    this.sliderToShow = slides;
  }
  
  name(name:string){
    this.eventTitle = name;
  }
}
