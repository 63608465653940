import { Injectable } from "@angular/core";

@Injectable()
export class BodasService {
  private bodas: Bodas[] = [
    {
      title: "BODA CYNTHIA Y LUIS",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia_Luis.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia_LuisMobile.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia.Luis2017-18.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%201.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%202.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%2053.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%2056.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/CynthiaLuis57.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%2059.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/CynthiaYLuis/Cynthia%20y%20Luis%2061.jpg"
      ]
    },
    {
      title: "BODA ITZI Y ARTURO",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/Itzi_ArturoDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/Itzi_ArturoMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1138.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1150.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1169.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1176.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1194.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/ItziYArturo/_SC_1217.JPG"
      ]
    },
    {
      title: "BODA MARIANA Y CARLOS",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/Mariana_CarlosDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/Mariana_CarlosMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1127.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1131.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1190.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1199.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1216.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1218.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1228.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/DSC_1229.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianaYCarlos/IMG_4655.JPG"
      ]
    },
    {
      title: "BODA MARIANA Y TOMMY",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/Mariana_TommyDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/Mariana_TommyMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0007.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0014.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0020.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0025.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0036.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0050.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0052.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_0060.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/MarianYTommy/DSC_1000.JPG"
      ]
    },
    {
      title: "BODA PAMELA Y GABRIEL",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/Pamela_GabrielDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/Pamela_GabrielMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0092.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0093.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0098.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0113.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0116.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0167.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0170.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0173.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/PamelaYGabriel/DSC_0186.JPG"
      ]
    },
    {
      title: "BODA SOFIA Y ALFREDO",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/Sof%C3%ADa_AlfredoDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/Sofia_AlfredoMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0161%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0167%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0178%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0192%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0209%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/bodas/SofiaYAlfredo/DSC_0211%201.JPG"
      ]
    }
  ];

  constructor() {}

  getBodas() {
    return this.bodas;
  }
}

export interface Bodas {
  title: string;
  imgDesktop: string;
  imgMobile: string;
  items: string[];
}
