import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MailingService } from "../../services/mailing.service";

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.scss"]
})
export class ContactoComponent {
  contacto: FormGroup;

  constructor(public mailing: MailingService) {
    this.contacto = new FormGroup({
      Nombre: new FormControl("", Validators.required),
      Telefono: new FormControl("", Validators.required),
      Email: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")
      ]),
      Mensaje: new FormControl("", Validators.required),
      MailingList: new FormControl("contactpage@mg.floristique.mx"),
      PageEmail: new FormControl("mg.floristique.mx"),
      Subject: new FormControl("Contacto Floristique"),
      Site: new FormControl("Floristique")
    });
  }

  async Enviar() {
    try {
      this.mailing.sendMail(this.contacto);
      if (this.mailing.error === false) {
        this.contacto.reset({
          Nombre: "",
          Telefono: "",
          Email: "",
          Mensaje: "",
          MailingList: "contactpage@mg.floristique.mx",
          PageEmail: "mg.floristique.mx",
          Subject: "Contacto Floristique",
          Site: "Floristique"
        });
      } else {
        this.mailing.error = true;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
