import { Component, OnInit } from "@angular/core";
import {
  CorporativosService,
  Corporativos
} from "../../services/corporativos.service";

@Component({
  selector: "app-corporativos",
  templateUrl: "./corporativos.component.html",
  styleUrls: ["./corporativos.component.scss"]
})
export class CorporativosComponent implements OnInit {
  corporativos: Corporativos[] = [];
  sliderCorporativos = [];
  eventTitle: string;

  constructor(private _corporativos: CorporativosService) {}

  ngOnInit() {
    this.corporativos = this._corporativos.getCorporativos();
  }

  showSlider(slider: string[]) {
    this.sliderCorporativos = slider;
  }

  name(name:string){
    this.eventTitle = name;
  }
}
