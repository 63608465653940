import { Injectable } from "@angular/core";

@Injectable()
export class CorporativosService {
  private corporativos: Corporativos[] = [
    {
      title: "PRESENTACIÓN CERVECERÍA MODELO APAN",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/InauguracionCoronaDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/InauguracionCoronaMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/DSC_0076.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/DSC_0134.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/DSC_0147.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/grupoModelo/DSC_0163.JPG"
      ]
    },
    {
      title: "HERRADURA ULTRA",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/herraduraUltra/HerraduraUltraDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/herraduraUltra/HerraduraUltraMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/herraduraUltra/IMG_1517.JPG"
      ]
    },
    {
      title: "TRUPER EVENTO HACIENDA",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/TruperEventoHaciendaDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/TruperHaciendaMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1413.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1416.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1426.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1433.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1436.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperEventoHacienda/DSC_1450.jpg"
      ]
    },
    {
      title: "TRUPER MILLESIME",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/TruperMillesimeDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/TruperMillesimeMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_0995.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1001.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1005.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1013.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1020.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1023.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1030.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1031.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1033.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosCorporativos/truperMillesime/DSC_1034.JPG"
      ]
    }
  ];

  constructor() {}

  getCorporativos() {
    return this.corporativos;
  }
}

export interface Corporativos {
  title: string;
  imgDesktop: string;
  imgMobile: string;
  items: string[];
}
