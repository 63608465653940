import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { BodasComponent } from "./bodas/bodas.component";
import { SocialesComponent } from "./sociales/sociales.component";
import { CorporativosComponent } from "./corporativos/corporativos.component";
import { DecoracionComponent } from "./decoracion/decoracion.component";
import { AcercaDeComponent } from "./acerca-de/acerca-de.component";
import { ContactoComponent } from './contacto/contacto.component';

export const APP_ROUTES: Routes = [
  { path: "home", component: HomeComponent },
  { path: "bodas", component: BodasComponent },
  { path: "eventos-corporativos", component: CorporativosComponent },
  { path: "decoracion-permanente", component: DecoracionComponent },
  { path: "eventos-sociales", component: SocialesComponent },
  { path: "about", component: AcercaDeComponent },
  { path: "contacto", component: ContactoComponent},
  { path: "", pathMatch: "full", redirectTo: "home" },
  { path: "**", pathMatch: "full", redirectTo: "home" }
];
