import { Injectable } from "@angular/core";

@Injectable()
export class SocialesService {
  private sociales: Sociales[] = [
    {
      title: "BAUTIZO CARLOS",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/BautizoCarlosDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/Bautizo_CarlosMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0535.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0539.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0543.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0546.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0555.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/BautizoCarlos/DSC_0562.JPG"
      ]
    },
    {
      title: "XV AÑOS TIFFANY",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/XVa%C3%B1osTiffanyDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/XVa%C3%B1osTiffanyMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/DSC_0053.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/DSC_0060.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/DSC_0069.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/eventosSociales/XVTiffany/DSC_0081.JPG"
      ]
    }
  ];

  constructor() {}

  getSociales() {
    return this.sociales;
  }
}

export interface Sociales {
  title: string;
  imgDesktop: string;
  imgMobile: string;
  items: string[];
}
