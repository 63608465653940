import { Injectable } from "@angular/core";

@Injectable()
export class DecoracionService {
  private decoracion: Decoracion[] = [
    {
      title: "HOTELES",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/HotelesDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/HotelesMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/_DSC4059.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/_DSC4077.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/_DSC4114.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/_DSC4136.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/DSC_0137%201.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/DSC_0137%202.jpg",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/DSC_0934.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/IMG_2150.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/IMG_2156.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/P1070062.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/P1150292.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/P1150784.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/P1160666.JPG",
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/hoteles/DSCN6367.jpg"
      ]
    },
    {
      title: "RESTAURANTES",
      imgDesktop:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/restaurantes/RestaurantesDesk.png",
      imgMobile:
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/restaurantes/RestaurantesMob.png",
      items: [
        "https://floritiquetanakarei.blob.core.windows.net/floristique/img/decoracionPermanente/restaurantes/P1160451.JPG"
      ]
    }
  ];

  constructor() {}

  getDecoracion() {
    return this.decoracion;
  }
}

export interface Decoracion {
  title: string;
  imgDesktop: string;
  imgMobile: string;
  items: string[];
}
