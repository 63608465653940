import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Routes
import { APP_ROUTES } from "./app.routes";

//Components
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { BodasComponent } from "./bodas/bodas.component";
import { SocialesComponent } from "./sociales/sociales.component";
import { CorporativosComponent } from "./corporativos/corporativos.component";
import { DecoracionComponent } from "./decoracion/decoracion.component";
import { AcercaDeComponent } from './acerca-de/acerca-de.component';
import { ContactoComponent } from './contacto/contacto.component';

//services
import { BodasService } from "src/services/bodas.service";
import { SocialesService } from "../services/sociales.service";
import { CorporativosService } from "../services/corporativos.service";
import { DecoracionService } from "../services/decoracion.service";
import { MailingService } from '../services/mailing.service';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    BodasComponent,
    SocialesComponent,
    CorporativosComponent,
    DecoracionComponent,
    AcercaDeComponent,
    ContactoComponent
  ],
  imports: [BrowserModule, RouterModule.forRoot(APP_ROUTES, { useHash: true }), FormsModule,
    ReactiveFormsModule, HttpClientModule],
  providers: [
    BodasService,
    SocialesService,
    CorporativosService,
    DecoracionService,
    MailingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
