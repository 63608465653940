import { Component, OnInit } from '@angular/core';
import { SocialesService, Sociales } from '../../services/sociales.service';

@Component({
  selector: 'app-sociales',
  templateUrl: './sociales.component.html',
  styleUrls: ['./sociales.component.scss']
})
export class SocialesComponent implements OnInit {
  sociales: Sociales[] = [];
  sliderSociales: string[];
  constructor(private _sociales: SocialesService) { }

  ngOnInit() {
    this.sociales = this._sociales.getSociales();
  }

  showSlider(slider: string[]) {
    this.sliderSociales = slider;
  }
}
